import axios from "axios"
import React, { useCallback, useEffect, useState } from "react"
import { Col, Row } from "react-bootstrap"

const Pricing1 = () => {
  const [hostpackage, setHostPackage] = useState([])
  const getPackageHost = useCallback(() => {
    axios(process.env.API_ENDPOINT + "subscription/plans?user_type=host")
      .then(res => {
        if (res.status === 200) {
          setHostPackage(res.data.data.result)
        }
      })
      .catch(e => {
        console.log(e)
      })
  }, [])

  useEffect(() => {
    getPackageHost()
  }, [])

  return (
    <section id="pricing-section1" className="position-relative py-5 mt-5">
      <div className="container py-3">
        <Row>
          <Col md="12" className="pt-5">
            <h1>The SuperBetter Host Account</h1>
            <h5>Find the right plan for your group</h5>
          </Col>
          <Col md="4" sm="12" className="mt-5">
            <div className="host-offering">
              <h3>What’s included:</h3>
              <ul>
                <li>Create squads of any size</li>
                <li>Host squad challenges</li>
                <li>Library of 90+ squad challenges</li>
                <li>Design your own challenges</li>
                <li>Monitor player progress</li>
                {/* {hostpackage.length > 0 &&
                  hostpackage[0].features.map((item, i) => (
                    <li key={"features-" + i}>{item}</li>
                  ))} */}
              </ul>
            </div>
          </Col>
          <Col md="8" sm="12" className="mt-5">
            {hostpackage.length > 0 &&
              hostpackage.map(
                (item, i) =>
                  item.is_active && (
                    <div className="plans" key={`host-plan-` + i}>
                      <div className="d-flex align-items-center flex-column flex-md-row">
                        <div className="title">{item.title}</div>
                        <div className="plan-info">
                          <div className="pricing"> Host up to {item.resources.players} players </div>
                          <p className="mb-0">
                          {item.pricing}
                          </p>
                        </div>
                      </div>
                    </div>
                  )
              )}

            <div className="plans">
              <div className="d-flex align-items-center flex-column flex-md-row">
                <div className="title">Custom</div>
                <div className="plan-info">
                  <div className="pricing">More than 200 players</div>
                  <p className="mb-0">
                  Contact us
                  </p>
                </div>
              </div>
            </div>
          </Col>
          <Col md="12">
            <a
              href={`${process.env.HOST_APP}signup`}
              target="_blank"
              className="host-account"
            >
              Select a Plan
            </a>
          </Col>
        </Row>
      </div>
    </section>
  )
}

export default Pricing1
