import { Link } from "gatsby"
import React from "react"
import { Col, Row } from "react-bootstrap"

const Pricing3 = () => {
  return (
    <section id="pricing-section3" className="position-relative py-5">
      <div className="container py-3">
        <Row>
          <Col md="12">
            <p>
             Interested in purchasing subscriptions for your organization?
            </p>
            <Link to="/contact" className="host-account">
              Contact Us
            </Link>
          </Col>
        </Row>
      </div>
    </section>
  )
}

export default Pricing3
