import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Container } from "react-bootstrap"
import Pricing1 from "../sections/pricing/pricing-1"
import Pricing2 from "../sections/pricing/pricing-2"
import Pricing3 from "../sections/pricing/pricing-3"

const Pricing = () => {
  return (
    <Layout>
      <Seo
        title="Subscribe | Host Account | Hero Account"
        description="Choose the Host plan that’s right for you, or subscribe to a low-cost Hero account."
        keywords="SuperBetter Pricing, SuperBetter Plans, SuperBetter Accounts"
      />
      <Pricing1 />
      <Pricing2 />
      <Pricing3 />
    </Layout>
  )
}

export default Pricing
