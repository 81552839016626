import axios from "axios"
import React, { useCallback, useEffect, useState } from "react"
import { Col, Row } from "react-bootstrap"

const Pricing2 = () => {
  const [userpackage, setUserPackage] = useState([])
  const getPackageHost = useCallback(() => {
    axios(process.env.API_ENDPOINT + "subscription/plans?user_type=user")
      .then(res => {
        if (res.status === 200) {
          setUserPackage(res.data.data.result)
        }
      })
      .catch(e => {
        console.log(e)
      })
  }, [])

  useEffect(() => {
    getPackageHost()
  }, [])

  return (
    <section id="pricing-section2" className="position-relative py-5">
      <div className="container py-3">
        <Row>
          <Col md="12">
            <h1>SuperBetter App Accounts</h1>
          </Col>
          <Col md="6" className="d-md-flex justify-content-md-end pe-md-4">
            <div className="player-plans">
              <div className="player-title">
                <div className="pricing-status">
                  {userpackage[0]?.pricing || "FREE"}
                </div>
                <h2>{userpackage[0]?.title || "PLAYER"}</h2>
                <p>Play with squads</p>
              </div>
              <div>
                <ul>
                  {userpackage.length > 0 &&
                    userpackage[0].features.map((item, i) => (
                      <li key={`user-features-` + i}>{item}</li>
                    ))}
                </ul>
                <a
                  href={`${process.env.WEB_APP}auth/register`}
                  className="get-started"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Sign Up
                </a>
              </div>
            </div>
          </Col>
          <Col md="6" className="d-md-flex justify-content-md-start ps-md-4">
            <div className="player-plans">
              <div className="player-title">
                <div className="pricing-status">
                  {userpackage[1]?.pricing || "$ 0/year"}
                </div>
                <h2>{userpackage[1]?.title || "HERO"}</h2>
                <p>Play on your own</p>
              </div>
              <div>
                <ul>
                  {userpackage.length > 0 &&
                    userpackage[1].features.map((item, i) => (
                      <li key={`user-features1-` + i}>{item}</li>
                    ))}
                </ul>
                <a
                  href={`${process.env.WEB_APP}auth/register`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="get-started"
                >
                 14 Days Free
                </a>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </section>
  )
}

export default Pricing2
